b, strong {
    font-family: 'Soehne Buch';
}

.form-control {
    input:placeholder-shown + label {
        visibility: hidden;
    }

    input:not(:placeholder-shown) + label {
        visibility: visible;
    }

    select:invalid + label {
        visibility: hidden;
    }
}
